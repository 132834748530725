import {Action} from '@ngrx/store';
import {IncidentKopf, Rolle, VerlaufEintrag} from '../../../clients/incidents';


export const PAGE_STATE_RESET = '[ZentralerLeitstandPage] Page State reset';
export const IGNORE_WS_ACTION = '[ZentralerLeitstandPage] WS Action ignoriert';

export const EINGANG_NEU_LADEN = '[ZentralerLeitstandPage] Eingang neu laden';
export const LISTE_NEU_LADEN = '[ZentralerLeitstandPage] Liste neu laden';

export const INCIDENT_AUSGEWAEHLT = '[ZentralerLeitstandPage] Incident ausgewählt';
export const SETZE_VERANSTALTUNGSRELEVANT = '[ZentralerLeitstandPage] Setze Veranstaltungsrelevant';
export const SETZE_VERANTWORTUNG_ZENTRAL = '[ZentralerLeitstandPage] Setze Verantwortung Zentral';
export const SETZE_VERANTWORTUNG_ABTEILUNG = '[ZentralerLeitstandPage] Setze Verantwortung Abteilung';
export const SETZE_DELEGATION_ROLLE = '[ZentralerLeitstandPage] Setze Delegation Rolle';
export const SETZE_PINNWAND_AKTIV = '[ZentralerLeitstandPage] Setze Pinnwand aktiv';
export const MELDUNG_AUSGEWAEHLT = '[ZentralerLeitstandPage] Meldung ausgewählt';
export const SETZE_ABGESCHLOSSENE_ANZEIGEN = '[ZentralerLeitstandPage] Setze Abgeschlossene anzeigen';
export const SETZE_LETZTE_MELDUNGEN_ANZEIGEN = '[ZentralerLeitstandPage] Setze Letzte Meldungen anzeigen';
export const SETZE_LETZTE_SYSTEM_MELDUNGEN_ANZEIGEN = '[ZentralerLeitstandPage] Setze Letzte System-Meldungen anzeigen';

export const POST_INCIDENT_ABSCHLIESSEN_UI = '[ZentralerLeitstandPage] Incident abschließen';
export const POST_INCIDENT_REAKTIVIEREN_UI = '[ZentralerLeitstandPage] Incident reaktivieren';
export const POST_INCIDENT_SPEICHERN_UI = '[ZentralerLeitstandPage] Incident speichern';
export const POST_MELDUNG_ERFASSEN_UI = '[ZentralerLeitstandPage] Meldung erfassen';

export const BILD_ID_HINZUFUEGEN = '[ZentralerLeitstandPage] Bild Id hinzufügen';
export const BILD_ID_ENTFERNEN = '[ZentralerLeitstandPage] Bild Id entfernen';


// PAGE STATE RESET
export class PageStateResetAction implements Action {
    readonly type = PAGE_STATE_RESET;
}

// IGNORE WS ACTION
export class IgnoreWSAction implements Action {
    readonly type = IGNORE_WS_ACTION;

    constructor(public payload: any) {
    }
}

export class EingangNeuLadenAction implements Action {
    readonly type = EINGANG_NEU_LADEN;

    constructor() {}
}

export class ListeNeuLadenAction implements Action {
    readonly type = LISTE_NEU_LADEN;

    constructor() {}
}

export class IncidentAusgewaehltAction implements Action {
    readonly type = INCIDENT_AUSGEWAEHLT;

    constructor(public payload: IncidentKopf) {}
}

export class SetzeVeranstaltungsrelevantAction implements Action {
    readonly type = SETZE_VERANSTALTUNGSRELEVANT;

    constructor(public payload: boolean) {}
}

export class SetzeVerantwortungZentralAction implements Action {
    readonly type = SETZE_VERANTWORTUNG_ZENTRAL;

    constructor(public payload: boolean) {}
}

export class SetzeVerantwortungAbteilungAction implements Action {
    readonly type = SETZE_VERANTWORTUNG_ABTEILUNG;

    constructor(public payload: string) {}
}

export class SetzeDelegationRolleAction implements Action {
    readonly type = SETZE_DELEGATION_ROLLE;

    constructor(public payload: Rolle) {}
}

export class SetzePinnwandAktivAction implements Action {
    readonly type = SETZE_PINNWAND_AKTIV;

    constructor(public payload: boolean) {}
}

export class MeldungAusgewaehltAction implements Action {
    readonly type = MELDUNG_AUSGEWAEHLT;

    constructor(public payload: VerlaufEintrag) {}
}

export class SetzeAbgeschlosseneAnzeigenAction implements Action {
    readonly type = SETZE_ABGESCHLOSSENE_ANZEIGEN;

    constructor(public payload: boolean) {}
}

export class SetzeLetzteMeldungenAnzeigenAction implements Action {
    readonly type = SETZE_LETZTE_MELDUNGEN_ANZEIGEN;

    constructor(public payload: boolean) {}
}

export class SetzeLetzteSystemMeldungenAnzeigenAction implements Action {
    readonly type = SETZE_LETZTE_SYSTEM_MELDUNGEN_ANZEIGEN;

    constructor(public payload: boolean) {}
}

export class PostIncidentAbschliessenUiAction implements Action {
    readonly type = POST_INCIDENT_ABSCHLIESSEN_UI;

    constructor() {}
}

export class PostIncidentReaktivierenUiAction implements Action {
    readonly type = POST_INCIDENT_REAKTIVIEREN_UI;

    constructor() {}
}

export class PostIncidentSpeichernUiAction implements Action {
    readonly type = POST_INCIDENT_SPEICHERN_UI;

    constructor() {}
}

export class PostMeldungErfassenUiAction implements Action {
    readonly type = POST_MELDUNG_ERFASSEN_UI;

    constructor(public payload: string) {}
}

export class BildIdHinzufuegenAction implements Action {
    readonly type = BILD_ID_HINZUFUEGEN;

    constructor(public payload: string) {}
}

export class BildIdEntfernenAction implements Action {
    readonly type = BILD_ID_ENTFERNEN;

    constructor(public payload: string) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
    | PageStateResetAction
    | IgnoreWSAction
    | EingangNeuLadenAction
    | ListeNeuLadenAction
    | IncidentAusgewaehltAction
    | SetzeVeranstaltungsrelevantAction
    | SetzeVerantwortungZentralAction
    | SetzeVerantwortungAbteilungAction
    | SetzeDelegationRolleAction
    | SetzePinnwandAktivAction
    | MeldungAusgewaehltAction
    | SetzeAbgeschlosseneAnzeigenAction
    | SetzeLetzteMeldungenAnzeigenAction
    | SetzeLetzteSystemMeldungenAnzeigenAction
    | PostIncidentAbschliessenUiAction
    | PostIncidentReaktivierenUiAction
    | PostIncidentSpeichernUiAction
    | PostMeldungErfassenUiAction
    | BildIdEntfernenAction
    | BildIdEntfernenAction
    ;
